<template>
  <Layout>
    <router-view />
  </Layout>
  <Notification ref="notification" />
</template>

<script>
import '@/assets/fonts/inter/inter.css'
import '@/assets/icons/phosphor/styles.min.css'
import '@/assets/css/all.min.css'
import '@/assets/css/custom.css'
import '@/assets/js/app'
import '@/assets/js/datatables'

import Layout from '@/views/layout/Layout.vue'
import Notification from '@/components/commons/Notification.vue'

export default {
  name: 'App',

  components: {
    Layout,
    Notification,
  },
}
</script>
