<template>
  <div class="navbar navbar-dark navbar-expand-lg py-1 navbar-static">
    <div class="container-fluid ps-1">
      <div class="navbar-brand">
        <router-link
          :to="`/home`"
          class="d-inline-flex align-items-center"
          title="Home"
        >
          <img
            src="@/assets/img/logos/logo_mini.png"
            class="h-16px"
            alt="Logo"
          />
          <span class="ms-2 text-white fs-lg">
            Sistema de Movimentação de Pessoal
          </span>
        </router-link>
      </div>
      <div class="d-xl-none">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
          title="Expandir/Recolher"
        >
          <i class="ph ph-list" />
        </button>
      </div>
      <div class="navbar-collapse collapse" id="navbar-navigation">
        <ul class="navbar-nav ms-auto">
          <li v-if="user.isAdmin" class="nav-item">
            <router-link
              to="/admin"
              class="navbar-nav-link rounded"
              title="Área Admin"
            >
              Área Admin
            </router-link>
          </li>
          <li class="nav-item dropdown ms-xl-1">
            <a
              href="#"
              class="navbar-nav-link rounded dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{
                user && user.codUsuario
                  ? user.codUsuario
                  : 'Usuário não identificado'
              }}
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <button class="dropdown-item" @click="logout" title="Sair">
                <i class="ph ph-sign-out me-2" />Sair
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.SIGN_IN)
      })
    },
  },
  mounted() {},
}
</script>
