import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import SignIn from '../views/auth/SignIn.vue'

const routes = [
  {
    name: 'SignIn',
    path: PATHS.SIGN_IN,
    component: SignIn,
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Admin',
    path: PATHS.ADMIN,
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    name: 'AdminUnidades',
    path: PATHS.ADMIN_UNIDADES,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/admin/unidades/Unidades.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    name: 'AdminFuncoes',
    path: PATHS.ADMIN_FUNCOES,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/admin/funcoes/Funcoes.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    name: 'AdminFluxos',
    path: PATHS.ADMIN_FLUXOS,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/admin/fluxos/Fluxos.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    name: 'AdminFluxosDetails',
    path: PATHS.ADMIN_FLUXOS_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/admin/fluxos/FluxoDetails.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    name: 'Movimentacoes',
    path: PATHS.MOVIMENTACOES,
    component: () =>
      import(
        /* webpackChunkName: "movimentacoes" */ '../views/movimentacoes/Movimentacoes.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'MovimentacoesDetails',
    path: PATHS.MOVIMENTACOES_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "movimentacoes" */ '../views/movimentacoes/MovimentacaoDetails.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  const { user, isLoggedInGSNMP } = store.getters

  if (isLoggedInGSNMP && to.name === 'SignIn') {
    return { name: 'Home' }
  }

  if (to.meta.requiresAuth) {
    if (!isLoggedInGSNMP && !localStorage.getItem('tokenGSNMP')) {
      return { name: 'SignIn' }
    }

    if (to.meta.requiresAdmin && !user.isAdmin) {
      return { name: 'Unauthorized' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInGSNMP && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.SIGN_IN)
  }
})

export default router
