const BASE_URL = '/'
const SIGN_IN = BASE_URL
const HOME = `${BASE_URL}home`
const ADMIN = `${BASE_URL}admin`
const ADMIN_UNIDADES = `${ADMIN}/unidades`
const ADMIN_FUNCOES = `${ADMIN}/funcoes`
const ADMIN_FLUXOS = `${ADMIN}/fluxos`
const ADMIN_FLUXOS_DETAILS = `${ADMIN_FLUXOS}/:idFluxo`
const MOVIMENTACOES = `${BASE_URL}movimentacoes`
const MOVIMENTACOES_DETAILS = `${MOVIMENTACOES}/:idMovimentacao`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  SIGN_IN,
  HOME,
  ADMIN,
  ADMIN_UNIDADES,
  ADMIN_FUNCOES,
  ADMIN_FLUXOS,
  ADMIN_FLUXOS_DETAILS,
  MOVIMENTACOES,
  MOVIMENTACOES_DETAILS,
  UNAUTHORIZED,
}
