import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createFluxo({ commit }, data) {
      try {
        return await api.post('/fluxos', data)
      } catch (error) {
        throw error
      }
    },

    async getFluxos({ commit }) {
      try {
        const response = await api.get('/fluxos')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFluxosAtivos({ commit }) {
      try {
        const response = await api.get('fluxos?status=1')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFluxo({ commit }, idFluxo) {
      try {
        const response = await api.get(`/fluxos/${idFluxo}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateFluxo({ commit }, data) {
      try {
        return await api.put(`/fluxos/${data.idFluxo}`, data.fluxo)
      } catch (error) {
        throw error
      }
    },

    async deleteFluxo({ commit }, idFluxo) {
      try {
        return await api.delete(`/fluxos/${idFluxo}`)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
