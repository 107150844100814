import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createMovimentacao({ commit }, data) {
      try {
        return await api.post('/movimentacoes', data)
      } catch (error) {
        throw error
      }
    },

    async getMovimentacoes({ commit }) {
      try {
        const response = await api.get('/movimentacoes')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getMovimentacao({ commit }, idMovimentacao) {
      try {
        const response = await api.get(`/movimentacoes/${idMovimentacao}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateMovimentacao({ commit }, data) {
      try {
        return await api.put(
          `/movimentacoes/${data.idMovimentacao}`,
          data.movimentacao,
        )
      } catch (error) {
        throw error
      }
    },

    async deleteMovimentacao({ commit }, idMovimentacao) {
      try {
        return await api.delete(`/movimentacoes/${idMovimentacao}`)
      } catch (error) {
        throw error
      }
    },

    async aprovationMovimentacao({ commit }, data) {
      try {
        return await api.post(
          `/movimentacoes/${data.idMovimentacao}/aprovacao`,
          data.movimentacao,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
