import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createEtapa({ commit }, data) {
      try {
        return await api.post(`/fluxos/${data.idFluxo}/etapas`, data.etapa)
      } catch (error) {
        throw error
      }
    },

    async getEtapas({ commit }, idFluxo) {
      try {
        const response = await api.get(`/fluxos/${idFluxo}/etapas`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEtapasAtivas({ commit }, idFluxo) {
      try {
        const response = await api.get(`/fluxos/${idFluxo}/etapas?status=1`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEtapa({ commit }, data) {
      try {
        const response = await api.get(
          `/fluxos/${data.idFluxo}/etapas/${data.idEtapa}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateEtapa({ commit }, data) {
      try {
        return await api.put(
          `/fluxos/${data.idFluxo}/etapas/${data.idEtapa}`,
          data.etapa,
        )
      } catch (error) {
        throw error
      }
    },

    async deleteEtapa({ commit }, data) {
      try {
        return await api.delete(
          `/fluxos/${data.idFluxo}/etapas/${data.idEtapa}`,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
