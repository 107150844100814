import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createUnidade({ commit }, data) {
      try {
        return await api.post('/unidades', data)
      } catch (error) {
        throw error
      }
    },

    async getUnidades({ commit }) {
      try {
        const response = await api.get('/unidades')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getUnidadesAtivas({ commit }) {
      try {
        const response = await api.get('/unidades?status=1')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getUnidade({ commit }, idUnidade) {
      try {
        const response = await api.get(`/unidades/${idUnidade}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUnidade({ commit }, data) {
      try {
        return await api.put(`/unidades/${data.idUnidade}`, data.unidade)
      } catch (error) {
        throw error
      }
    },

    async deleteUnidade({ commit }, idUnidade) {
      try {
        return await api.delete(`/unidades/${idUnidade}`)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
