import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async createFuncao({ commit }, data) {
      try {
        return await api.post('/funcoes', data)
      } catch (error) {
        throw error
      }
    },

    async getFuncoes({ commit }) {
      try {
        const response = await api.get('/funcoes')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFuncoesAtivas({ commit }) {
      try {
        const response = await api.get('/funcoes?status=1')
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFuncao({ commit }, idFuncao) {
      try {
        const response = await api.get(`/funcoes/${idFuncao}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateFuncao({ commit }, data) {
      try {
        return await api.put(`/funcoes/${data.idFuncao}`, data.funcao)
      } catch (error) {
        throw error
      }
    },

    async deleteFuncao({ commit }, idFuncao) {
      try {
        return await api.delete(`/funcoes/${idFuncao}`)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
