<template>
  <div class="d-flex justify-content-center align-items-center">
    <form class="login-form" @submit.prevent="auth">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <div
              class="d-inline-flex align-items-center justify-content-center my-2"
            >
              <img src="@/assets/img/logos/logo.png" class="h-72px" />
            </div>
            <h4 class="text-primary">Movimentação de Pessoal</h4>
          </div>
          <div class="mb-3">
            <label class="form-label">Usuário:</label>
            <div class="form-control-feedback form-control-feedback-start">
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.codUsuario.$errors.length }"
                placeholder="Usuário"
                v-model="v$.codUsuario.$model"
              />
              <div class="form-control-feedback-icon">
                <i class="ph ph-user text-muted"></i>
              </div>
              <span
                v-if="v$.codUsuario.$error && v$.codUsuario.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Senha:</label>
            <div class="form-control-feedback form-control-feedback-start">
              <input
                type="password"
                class="form-control"
                :class="{ 'is-invalid': v$.password.$errors.length }"
                placeholder="•••••••••••"
                v-model="v$.password.$model"
              />
              <div class="form-control-feedback-icon">
                <i class="ph ph-lock text-muted"></i>
              </div>
              <span
                v-if="v$.password.$error && v$.password.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="mb-3">
            <button
              type="submit"
              class="btn btn-primary w-100"
              title="Login"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>Login</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'SignIn',

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      codUsuario: 'mestre',
      password: 'schkdskn2000',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('signIn', {
            codUsuario: this.codUsuario,
            password: this.password,
          })
          this.disableButton = false
          this.$router.push(PATHS.HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      codUsuario: {
        required,
      },
      password: {
        required,
      },
    }
  },

  mounted() {
    if (localStorage.getItem('sessionError')) {
      this.$root.$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },
}
</script>
