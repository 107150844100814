<template>
  <Navbar v-if="showComponent" />
  <div class="page-content" :style="addBackground">
    <div class="content-wrapper">
      <div class="content-inner">
        <div
          class="content"
          :class="{
            'd-flex justify-content-center align-items-center': addClass,
          }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/commons/Navbar.vue'
import backgroundImage from '@/assets/img/backgrounds/background-01.jpg'

export default {
  name: 'Layout',

  components: {
    Navbar,
  },

  data() {
    return {
      routesAddBackgroundAndClass: ['SignIn'],
      routesNotShowNavbar: ['SignIn', 'Unauthorized', 'NotFound'],
    }
  },

  computed: {
    showComponent() {
      return !this.routesNotShowNavbar.includes(this.$route.name)
    },

    addBackground() {
      if (this.routesAddBackgroundAndClass.includes(this.$route.name)) {
        return {
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
      }
      return {}
    },

    addClass() {
      return this.routesAddBackgroundAndClass.includes(this.$route.name)
    },
  },
}
</script>
