import auth from './modules/auth/auth'
import unidades from './modules/unidades/unidades'
import funcoes from './modules/funcoes/funcoes'
import tipoMovimentacoes from './modules/tipoMovimentacoes/tipoMovimentacoes'
import tipoVagas from './modules/tipoVagas/tipoVagas'
import fluxos from './modules/fluxos/fluxos'
import etapas from './modules/fluxos/etapas'
import movimentacoes from './modules/movimentacoes/movimentacoes'

export default {
  auth,
  unidades,
  funcoes,
  tipoMovimentacoes,
  tipoVagas,
  fluxos,
  etapas,
  movimentacoes,
}
