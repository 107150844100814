import api from '@/services/api'

export default {
  state: {
    isLoggedInGSNMP: !!localStorage.getItem('tokenGSNMP'),
    userGSNMP: {
      codUsuario: '',
      isAdmin: false,
    },
  },

  mutations: {
    AUTH(state, user) {
      state.isLoggedInGSNMP = true
      state.userGSNMP = {
        codUsuario: user.codUsuario,
        isAdmin: user.isAdmin,
      }
    },

    LOGOUT(state) {
      state.isLoggedInGSNMP = false
      state.userGSNMP = {
        codUsuario: '',
        isAdmin: false,
      }
    },
  },

  actions: {
    async signIn({ commit }, credentials) {
      try {
        const response = await api.post('/auth/sign-in', {
          codUsuario: credentials.codUsuario,
          password: credentials.password,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        const user = {
          codUsuario: data && data.user ? data.user.codUsuario : '',
          isAdmin: data && data.user ? data.user.isAdmin : false,
        }

        localStorage.setItem('tokenGSNMP', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },

  getters: {
    isLoggedInGSNMP: (state) => state.isLoggedInGSNMP,
    user: (state) => state.userGSNMP,
  },
}
